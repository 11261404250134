export default function () {
    const treeLi = document.querySelectorAll('#tree li');

    if (treeLi.length > 0) {
        treeLi.forEach(function (li) {
            const dataCount = li.getAttribute('data-count');
            const aElem = li.querySelectorAll('a')[0];

            if (!aElem) return;

            const smallElem = document.createElement('small');

            smallElem.textContent = `(${dataCount})`;
            aElem.appendChild(smallElem);
        });
    }
}
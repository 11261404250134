import { on } from 'delegated-events';
import createPills from './create-pills';
import wishlist from './wishlist';
import sorter from './sorter';

import { showLoader, hideLoader } from './utils/utils';

function pjaxFormSubmit(form) {
    if (typeof (Event) !== 'function') {
        document.querySelector(`${form}`).submit();
    }

    document.querySelector(`${form}`).dispatchEvent(new Event('submit', {
        bubbles: true,
        cancelable: true,
        type: 'submit'
    }));
}

export default function () {
    const pjaxContainer = document.querySelector('#pjax-container');

    if (!pjaxContainer) return;

    import('pjax/pjax.js').then(module => {
        const Pjax = module.default;

        new Pjax({
            elements: "#filter",
            selectors: ["#pjax-container"],
            scrollRestoration: false,
            scrollTo: false,
            cacheBust: false,
            scrollTo: false,
            switches: {
                "#pjax-container": Pjax.switches.innerHTML
            }
        });

        document.addEventListener('pjax:send', function () {
            showLoader();
        });

        document.addEventListener('pjax:complete', function () {
            window.lazyLoadInstance.update();
            hideLoader();
        });

        document.addEventListener('pjax:complete', function (xhr) {
            createPills();
            wishlist();
            sorter();

            const filterBottomButton = document.querySelector('.close-filter-bottom > span');

            if (filterBottomButton) {
                filterBottomButton.setAttribute('data-count', `(${xhr.request.getResponseHeader('x-filtered-count')})`);
            }
        });

        on('click', '.sorter input[type="radio"]', function () {
            document.querySelector('#filter [name="sort"]').value = this.getAttribute('data-sort-type');
            pjaxFormSubmit('#filter');
        });

        on('change', '#filter input[type="checkbox"]', function () {
            pjaxFormSubmit('#filter');
        });

        on('click', '#pills-container .single-pill[data-pill-id]', function () {
            const pillId = this.getAttribute('data-pill-id');
            const filterInput = document.querySelector(`.gs-filtr-container input[id="${pillId}"]`);

            filterInput.checked = false;
            pjaxFormSubmit('#filter');
        });

        on('click', '.remove-all-filters', function () {
            const priceFiltering = document.querySelector('#filter .price-filtering');

            import('noUiSlider').then(({ default: noUiSlider }) => {
                if (priceFiltering) {
                    const handles = priceFiltering.querySelector('#price-filter-handles');

                    handles.noUiSlider.set([
                        handles.getAttribute('data-price-min'),
                        handles.getAttribute('data-price-max')
                    ]);
                }

                document.querySelectorAll('.gs-filtr-container input[type="checkbox"]:checked').forEach(input => {
                    input.checked = false;
                });

                pjaxFormSubmit('#filter');
            });
        });

        createPills();
    });
}

export { pjaxFormSubmit };